import React, { useState } from 'react';
import { getRefreshJwt, getSessionJwt, serverHost } from '../utils';

const VerifySession = (props: { wizard?: boolean }) => {
	const { wizard } = props;
	const [verified, setVerified] = useState('---');
	const [textColor, setTextColor] = useState('gray');

	const verify = async () => {
		if (getSessionJwt() === null) {
			setVerified('No session to verify');
			setTextColor('white');
			return;
		}

		try {
			const resp = await fetch(`${serverHost}/api/verifySession`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json;charset=UTF-8'
				},
				credentials: 'include',
				body: JSON.stringify({
					sessionJwt: getSessionJwt(),
					refreshJwt: getRefreshJwt(),
					wizard
				})
			});
			switch (resp.status) {
				case 200:
					setVerified('Session is valid');
					setTextColor('aquamarine');
					break;
				case 401:
					setVerified('Session is invalid');
					setTextColor('magenta');
					break;
				default:
					setVerified('Something went wrong');
					setTextColor('red');
			}
		} catch (e) {
			setVerified('Something went wrong (technical failure)');
			setTextColor('orange');
		} finally {
			localStorage.clear();
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				marginTop: '20px',
				fontSize: '14px',
				color: textColor
			}}
		>
			<button
				type="button"
				id="verify"
				style={{
					width: '40pt'
				}}
				onClick={verify}
			>
				Verify
			</button>
			{verified}
		</div>
	);
};

VerifySession.defaultProps = {
	wizard: false
};

export default VerifySession;
