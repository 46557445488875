import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import FlowPage from './components/Flow/FlowPage';
import WizardFlowPage from './components/Flow/WizardFlowPage';
import FlowsList from './components/FlowsList';
import MagicLink from './components/MagicLink';
import MagicLinkSent from './components/MagicLinkVerification';
import OAuthExchange from './components/OAuthExchange';
import OidcHosted from './components/Oidc/OidcPage';
// import PasswordSignUp from './components/PasswordSignup';
// import PasswordSignIn from './components/PasswordSignupIn';
import VerifySession from './components/VerifySession';
import WizardPage from './components/Wizard/WizardPage';
import { magicLink } from './magiclink';
import { oauthProviders, oauth } from './oauth';
// import { passwordSignUp } from './password';

const Title = ({ label }: { label: string }) => (
	<span style={{ fontSize: '18px', margin: '10px' }}>{label}</span>
);

const Root = () => (
	<div className="App">
		<header className="App-header">
			<Title label="OAuth" />
			<div className="oauth-list">
				{Object.values(oauthProviders).map((provider) => (
					<button
						type="button"
						key={provider.key}
						onClick={() => {
							oauth(provider.key);
						}}
					>
						{provider.name}
					</button>
				))}
			</div>

			<Title label="Magic Link" />
			<MagicLink
				onClick={(email: string) => {
					magicLink(email);
					alert('Magic link sent'); // eslint-disable-line
				}}
			/>
			{/*
			<Title label="Password Sign-Up" />
			<PasswordSignUp
				onClick={(email: string, password: string) => {
					passwordSignUp(email, password);
					// alert('Password Sign Up sent'); // eslint-disable-line
				}}
			/>

			<Title label="Password Sign-In" />
			<PasswordSignIn
				onClick={(email: string, password: string) => {
					passwordSignUp(email, password);
					// alert('Password Sign Up sent'); // eslint-disable-line
				}}
			/> */}

			<Title label="Flows" />
			<FlowsList />

			<Title label="More" />
			<Link id="wizard" to="/wizard" style={{ margin: '6px' }}>
				{' '}
				Wizard{' '}
			</Link>
			<Link id="oidc" to="/oidc" style={{ margin: '6px' }}>
				{' '}
				Oidc{' '}
			</Link>

			<Title label="Verify" />
			<VerifySession />
		</header>
	</div>
);

const App = () => (
	<Routes>
		<Route path="/oidc" element={<OidcHosted />} />
		<Route path="/oauth" element={<OAuthExchange />} />
		<Route path="/magiclink/verify" element={<MagicLinkSent />} />
		<Route path="/success" element={<div>Yay</div>} />
		<Route path="/flow" element={<FlowPage />} />
		<Route path="/wizard" element={<WizardPage />} />
		<Route path="/wizard/flow" element={<WizardFlowPage />} />
		<Route path="/password/success" element={<div>Password SignUp OK</div>} />
		<Route path="/" element={<Root />} />
	</Routes>
);

export default App;
