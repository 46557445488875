import React, { lazy, Suspense, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { handleSessionFlowResponse } from '../../utils';

// we load descope component in lazy manner (i.e. only when element is rendered on the screen for the first time)
// we do this because the sdk code uses localStorage on init, and we want to make sure we set it before this component is loaded
const Descope = lazy(() =>
	import('@descope/react-sdk').then((module) => ({ default: module.Descope }))
);
const AuthProvider = lazy(() =>
	import('@descope/react-sdk').then((module) => ({
		default: module.AuthProvider
	}))
);

const Flow = (props: {
	projectId: string;
	baseUrl: string;
	onSuccess: () => void;
}) => {
	const { projectId, baseUrl, onSuccess } = props;
	const [searchParams] = useSearchParams();

	const loggedIn = useCallback(
		(e: CustomEvent) => {
			handleSessionFlowResponse(e);
			onSuccess();
		},
		[onSuccess]
	);

	return (
		<Suspense>
			<AuthProvider projectId={projectId || ''} baseUrl={baseUrl || ''}>
				<div
					style={{
						height: '100vh',
						position: 'relative',
						backgroundImage:
							'linear-gradient(rgb(79,180,184),rgb(195, 143, 135))'
					}}
				>
					<div
						style={{
							margin: 'auto',
							padding: 0,
							maxWidth: '750px',
							position: 'relative',
							top: 200
						}}
					>
						<Descope
							flowId={searchParams.get('id') || 'dummy'}
							onSuccess={loggedIn}
							locale={searchParams.get('locale') || undefined}
						/>
					</div>
				</div>
			</AuthProvider>
		</Suspense>
	);
};

export default Flow;
